<template>
  <div>
    <div class="app-container container" v-if="pagelist">
      <h3>{{pagelist.ProfessionName}}</h3>
      <div class="body titlebox">
        <div>
          <h5>所属类别：</h5>
          {{pagelist.CategoryName}}
        </div>
        <div>
          <h5>兴趣代码：</h5>
          {{pagelist.InterestCode}}
        </div>
      </div>
      <div class="videoboxmain titlebox" v-if="pagelist.VideoUrl">
        <div>
          <h4>职业新说</h4>
          <div class="videoboxbg">
          <video class="videobox" :src="pagelist.VideoUrl" controls="controls" controlsList="nodownload"></video>

          </div>
          
        </div>
      </div>
      <div class="titlebox" v-if="pagelist.ProfessionDesc">
        <h4>职业概况</h4>
        <div class="content">{{pagelist.ProfessionDesc}}</div>
      </div>
      <div class="titlebox" v-if="pagelist.Prospect">
        <h4>职业前景</h4>
        <div class="content">{{pagelist.Prospect}}</div>
      </div>
      <div class="titlebox" v-if="pagelist.DevelopmentPath">
        <h4>发展路径</h4>
        <div class="content">{{pagelist.DevelopmentPath}}</div>
      </div>
      <div class="titlebox" v-if="pagelist.ProfessionContent">
        <h4>职业内容</h4>
        <div class="content">{{pagelist.ProfessionContent}}</div>
      </div>
      <div class="titlebox" v-if="pagelist.Requirement">
        <h4>从业要求</h4>
        <div class="content">{{pagelist.Requirement}}</div>
      </div>
      <div v-if="pagelist.ExtendList">
        <div v-for="(item,index) in pagelist.ExtendList" :key="index">
          <div  class="titlebox" v-if="item.Items&&item.Items.length>0">
            <h4>{{item.Type}}</h4>
            <div
              class="Items"
              v-for="(ite,ind) in item.Items"
              :key="ind"
            >{{ite.Title}}：{{ite.Content}}</div>
          </div>
        </div>
      </div>
        <div class="titlebox" v-if="pagelist.SpecialtyList&&pagelist.SpecialtyList.length>0">
        <h4>推荐专业</h4>
        <div class="content">
          <div class="Profession">
            <div
              v-for="(item,index) in pagelist.SpecialtyList"
              :key="index"
              @click="goprofess(item)"
            >{{item.SpecialtyName}}</div>
          </div>
        </div>
      </div>
    </div>
    <hot-profession></hot-profession>
  </div>
</template>

<script>
import API from '@/api/config'

// import { ProfessionInfo } from "@/api/career/profession";
import hotProfession from "@/views/tool/profession/local-components/HotProfession"
export default {
  name:'Profession-detail',
  components:{
    hotProfession
  },
  data() {
    return {
      ID: "",
      pagelist: null,
    };
  },
  created() {
    this.init();
  },
  watch: {
    $route: function(to, from) {
      //执行数据更新查询
      this.init();
    }
  },

  methods: {
    // 专业详情
   goprofess(val) {
      this.$router.push({
       path: "/specialty-detail",
        query: { SpecialtyCode: val.SpecialtyCode }
      });
    },
    init() {
      if (this.$route.query.ID) {
        this.ID = this.$route.query.ID;
        API.Profession.ProfessionInfo({
          Id: this.ID
        }).then(res => {
          this.pagelist = res.data;
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.body {
  div {
    width: 100%;
    margin-bottom: 20px;
    h5 {
      display: inline;
    }
  }
}
.videoboxmain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5;
}
.videoboxbg{
width: 60%;
  height: 300px;
  background: #ccc;
}
.videobox {
  height: 100%;
  width: 100%;
}
.titlebox {
  border-bottom: 1px solid #eee;
  padding: 40px 0;
}
.content {
  white-space: pre-wrap;
  line-height: 1.5;
  color: #666;
   .Profession {
      display: flex;
      flex-wrap: wrap;
      & > div {
        padding: 4px;
        cursor: pointer;
        &:hover {
          color: rgba(var(--themecolor),1);
        }
      }
    }
}
.Items {
  line-height: 1.5;
  color: #666;
}

</style>
